/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {

        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages



                // Navigation
                // var navMenu     = $('.header__nav');
                // var navOpen  = $('.nav_open');
                // var navClose   = $('.nav_close');
                //
                // navOpen.click(
                //     function(){
                //         navMenu.toggle('fast');
                //     }
                // );
                //
                // navClose.click(
                //     function(){
                //         navMenu.toggle('fast');
                //     }
                // );
                //
                //
                // // AOS - animate on scroll
                // // setting
                // AOS.init({
                //     offset: 100,
                //     once: true,
                // });

                // Slider
                var sliderSldr = $('.box--slider .items');

                sliderSldr.slick({
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 4000,
                    infinite: true,
                    pauseOnHover: true,
                    dots: true,
                    rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
                });


                // Referenze
                var referenceSldr = $('.box--reference .items');

                referenceSldr.slick({
                    arrows: false,
                    autoplay: false,
                    autoplaySpeed: 4000,
                    infinite: true,
                    pauseOnHover: true,
                    dots: true,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    responsive: [
                        {
                            breakpoint: 1080,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            }
                        },
                        {
                            breakpoint: 640,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                          }
                        },
                    ],
                    rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
                });



                // Accordion
                // Add inactive to accordion__toggle, add active to first
                // close all, open first item
                $('.accordion__toggle').toggleClass('inactive');
                //$('.accordion__toggle').first().toggleClass('active').toggleClass('inactive');

                // Add inactive to accordion__content, add active to first
        		$('.accordion__content').addClass('inactive');
        		//$('.accordion__content').first().removeClass('hide').addClass('selected');

                $('.accordion__toggle').click(function(){

                	/*if($(this).is('.inactive')) {
                		$(this).toggleClass('active').toggleClass('inactive');
                	} else {
                		$(this).toggleClass('active').toggleClass('inactive');
                	}*/
                    if( $(this).hasClass('inactive') ){
                        $(this).removeClass('inactive');
                        $(this).addClass('active');
                        $(this).next().removeClass('inactive');
                        $(this).next().addClass('active');
                    } else {
                        $(this).removeClass('active');
                        $(this).addClass('inactive');
                        $(this).next().removeClass('active');
                        $(this).next().addClass('inactive');
                    }

                	//Expand or collapse this panel
                	//$(this).next().slideToggle('fast');

                	//Hide the other panels
                    //$('.accordion__toggle').not($(this)).removeClass('active').addClass('inactive');
                	//$('.accordion__content').not($(this).next()).removeClass('active').addClass('inactive');

                });



            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
